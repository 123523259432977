import { render, staticRenderFns } from "./UserActivity.vue?vue&type=template&id=272cbfdc&scoped=true&"
import script from "./UserActivity.vue?vue&type=script&lang=js&"
export * from "./UserActivity.vue?vue&type=script&lang=js&"
import style0 from "./UserActivity.vue?vue&type=style&index=0&id=272cbfdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272cbfdc",
  null
  
)

export default component.exports